import { Component } from '@angular/core';
import { MENU_ITEMS } from './andromeda-menu';
import { NbMenuModule, NbMenuService, NbSidebarService } from '@nebular/theme';

@Component({
  selector: 'andromeda-main-menu',
  styleUrls: ['./menu.component.scss'],
  template: `
    <nb-menu [items]="menu"></nb-menu>
  `,
})
export class MenuComponent {
  menu = MENU_ITEMS;

  constructor(
    private menuService: NbMenuService,
    private sidebarService: NbSidebarService,
  ) {
    this.menuService.onItemClick().subscribe((event) => {
      const viewportWidth = window.innerWidth;

      if (viewportWidth < 576) {
        this.sidebarService.collapse('menu-sidebar');
      }
      else if (viewportWidth < 1200) {
        this.sidebarService.compact('menu-sidebar');
      }

    });
  }
}
